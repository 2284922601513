@value first from '../value/values.css';
@value second from '../value/values.css';
@value medium from '../value/values.css';
.productBox {
    margin: 0px;
}

.photo {
    width: 100%;
    height: 230px;
    border-radius: 2%;
    background-color: first;
}


.text {
    background-color: white;
    color: black;
    position: relative;
    height: 470px;
    max-width: 340px;
    min-width: 220px;
    border-radius: 2%;
    border-bottom: 2px first solid;
}
.textHead {
    margin: 3% 5% 0 5% !important;
    color: first;
    height: 60px;
}
.textBody {
    padding: 5% 7% 15% !important;
    text-align: justify;
}

.text:hover > .photo,.text:hover > .textBody{
    opacity: 0;
    transition: ease-in-out 250ms all;
}

.text:hover{
    background-color: first;
    transition: ease-in-out 250ms all;
}

.text:hover > .textHead{ 
    margin-top: -100px !important;
    color: white;
    text-align: center;
    font-size: 35px;
    transition: ease-in-out 250ms all;
}

@media (min-width:medium){
    .textHead{
        font-size: 23px;
    }
}

@media (max-width:medium){
    .textHead{
        font-size: 20px;
    }
    .textBody{
        font-size: 16px;
    }
}