@value first from '../../value/values.css';
.productTextBody{
    line-height: 33px;
    text-align: justify;
}

.green{
    color: first;
    padding: 10px;
}
