@value first from '../value/values.css';
@value third from '../value/values.css';
@value medium from '../value/values.css';

@media(min-width:medium){
    .contact{
        padding: 5% 0%;
        -webkit-appearance: none !important;
    }
    .contactRow{
        margin: 5%;
    }
    .contactColumn{
        display: flex !important;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .contactBody{
        line-height: 35px;
        font-size: 17px;
    }
    .googleMap{
        width: 100%;
        height: 700px;
    }
}
@media(max-width:medium){
    .contact{
        padding: 5% 5%;
        -webkit-appearance: none !important;
    }
    .contactRow{
        margin-top: 3%;
    }
    .contactColumn{
        display: flex !important;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 50px !important;
    }
    .contactBody{
        line-height: 40px;
    }
    .googleMap{
        width: 100%;
        height: 300px;
    }

}
.green{
    color: first;
    text-align: center;
}
.contactImg{
    width: 100%;
    height: auto;
}