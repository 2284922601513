@value medium from './components/value/values.css';

@media(min-width: medium){

.App {
  margin-top: 5em;
  -webkit-appearance: none;
}
}

@media(max-width: medium){

.App {
  overflow: hidden;
  -webkit-appearance: none;
}
}

