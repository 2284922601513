@value second from '../value/values.css';
@value first from '../value/values.css';
@value third from '../value/values.css';
@value dark1 from '../value/values.css';
@value medium from '../value/values.css';
@value big from '../value/values.css';

@media(min-width:medium) and (max-width:big){
    .companyInfo{
        height: 1100px;
        margin: 4em 0 4em 0 !important;
        position: relative ;
    
    }
}
@media(min-width:big){
    .companyInfo{
        height: 1100px;
        margin: 4em 10% 4em 10% !important;
        position: relative ;
    
    }
}

@media(min-width:medium){

.companyStart{
    height: 800px;
    width: 860px;
    background-color: rgb(115, 173, 106, 0.6);
    margin: 10% 10% 10% 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.companyTitleB{
    margin-top: 20px;
    font-size: 23px;

}


.companyInfoBack{
    width: 60%;
    object-fit: cover;
    height: 1100px;
    position: absolute;
    rotate: 90;
}

.companyInfoText{
    text-align: justify;
    padding:5%;
    color: white;
    width: 50%;
    background-color: dark1;
    height:989px;
    position: absolute;
    top:10.1%;
    left: 50%;
    line-height: 35px;
}

.companyMoreInfo{
    background-color: second;
    padding: 0 !important;
}

.companyMoreInfoBack{
    margin: 4em 10%;
    background-color: white;
    padding: 5%;
    text-align: center;
    color: first;
}

.companyMainButton{
    margin: 20px 10px;
    width: 150px;
}

.companyBackground{
    background-color: third;
    padding-bottom: 6em !important;
}

.companyGreen{
    color: first;
    margin: 3% !important;
}

.companyProjectColumn{
    background-color: white;
    margin: 0 0 5px 6.25% !important;
    padding: 1.5% 5% !important;
}
.companyProjectImage{
    margin: 0 6.25% 0 0 !important;
    padding: 0 !important;
}

}
@media(max-width:medium){
.companyStart{
    height: 100%;
    background-color: rgb(115, 173, 106, 0.6); 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.companyRow{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.companyInfo{
    margin: 0 !important;
}

.companyTitleB{
    margin-top: 20px;
    font-size: 23px;
    margin: 0 3% 0 3%;
}

.companyInfoText{
    text-align: justify;
    padding:5%;
    color: white;
    width: 100%;
    background-color: dark1;
    height: auto;   
}

.companyMoreInfo{
    padding: 0 !important;
}

.companyMoreInfoBack{
    background-color: third;
    padding: 5%;
}

.companyMainButton{
    margin: 20px 10px;
    width: 150px;
}

.companyBackground{
    background-color: third;
    padding-bottom: 2em !important;
}

.companyGreen{
    color: first;
    margin: 3% !important;
}

.companyProjectColumn{
    background-color: white;
    margin: 0 40px 40px 40px !important;
    padding: 5% 8% !important;
}
.companyProjectImage{
    margin: 40px 40px 0 40px  !important;
    padding: 0 !important;
}
}

.ImagesCover{
    object-fit: cover;
    width: 100%;
}

.Boxs{
    display: flex;
    justify-content: space-around;
}

.Box{
    flex-basis: 26% !important;
    text-align: justify;
    color: black;
}
.companyButton {
    border: 0.5px solid dark1;
    color: black;
    padding: 15px 0;
    margin-top: 50px;
    width: 100%;
}

.companyButton:hover {
    background-color:dark1;
    color: white;
}
.companyInfoTextTitle{
    text-align: left;
}