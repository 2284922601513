@value dark1 from '../value/values.css';
@value third from '../value/values.css';
@value four from '../value/values.css';
@value first from '../value/values.css';
@value second from '../value/values.css';
@value medium from '../value/values.css';
@value big from '../value/values.css';


.homeStartBack{
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-attachment: fixed !important;
    background-size: cover !important ;
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 110%;
    z-index: -1;
}

.startHrefUE{
    height: 170px;
    width: 170px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    margin: 20px 0 0 20px;
}

.startHrefEnplus{
    height: 100px;
    width: auto;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5%;
    margin: 5px;
}

.startHrefFSC{
    height: 220px;
    width: 160px;
    border-radius: 1%;
    padding: 20px;
    margin: 0 10px 0 10px;
    background-color: rgba(255, 255, 255, 1);
}

.FSCbody{
    background-color: rgba(255, 255, 255, 0.1);;
    color: white !important;
    width: 160px;
    margin: 0 10px 0 10px;
}

.startHrefPEFC{
    height: 100px;
    width: 100px;
    margin: 20px;
}

.homeStart{
    display: flex;
    align-self: center;
    text-align: center;
    color: white;
    padding-top: 12%  !important;
}
.homeMainButton{
    margin: 20px 2%;
    width: 200px;
    height: 60px;
    font-size: 20px !important;
}
.homeBody{
    text-align: justify;
    line-height: 35px;
}
.homeRow1{
    background-color: dark1;
    color: white;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    margin: 0px;
}
.homeRow2{
    background-color: third;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    color: first;
}
.homeColumn{
    padding: 0 !important;
}

.homeBodyAdvans{
    font-weight: bold;
    border-bottom: 1px solid first;
    width: 250px;
    padding-bottom: 20px;
}

.ImagesCover{
    object-fit: cover;
    height: 400px;
}

.homeDate{
    font-weight: 700;
}

.homeProductsImg{
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important ;
    border-right:6px solid white;
    border-bottom:6px solid white;
    padding: 0 !important;
}

.homeProductsTitle{
    text-align: center;
    color: dark1;
    background-color: white;
    padding: 50px 0;
    border-top:0.5px solid white;
    border-bottom:0.5px solid white;
}


.homeProductsContent{
    width: 100%;
    height: 100%;
    padding: 10%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    position: relative;
}

.homeProductsBody{
    position: absolute;
    opacity: 0;
    text-align: center;
}


.homeProductsContent:hover{
    transition: ease-in-out all 200ms;
    background-color: rgba(228, 228, 228, 0.911);
    color: black;
}

.homeProductsContent:hover > .homeProductsBody{
    transition: ease-in-out all 200ms;
    opacity: 1;
    position: initial;
}

.homeContact{
    background-color: first;
    padding: 30px 20% !important;
    color: white;
}


.homeContactButton{
    margin-top: 20px;
    padding: 15px 80px !important;
}

.homeMazo{
    display: flex !important;
    flex-direction: column;
}

.homeMazoA{
    padding: 2%;
    align-self: center;
}

.homeMazoImg{
    width: 100%;
    padding: 3%;
    border-left: rgb(226, 226, 226) 5px solid;
    border-right: rgb(226, 226, 226) 5px solid;
}

@media(min-width: medium) and (max-width:big) {
    .startCertificates{
        display: flex;
        flex-direction: row;    
        align-items: flex-end;
    }
    
    .startHref{
        display: flex;
        width: 100%;
        height: 400px;
        align-items: flex-end;
        padding-bottom: 70px;
        justify-content: space-between;
    }

    .homeInfo{
        margin: 10% 13%;
        display: flex;
        flex-direction: column;
        align-items: center; 
    }
    .homeProducts{
        margin: 0 7% !important;
    }

    .homeRow2{
        text-align: center;
    }

    .homeMazoA{
        width: 80%;
    }
    .homeContactInfo{
        text-align: center;
    }
    .homeProductsBody{
        font-size: 22px;
    }
}

@media(min-width: big){
    .startCertificates{
        display: flex;
        flex-direction: row;    
        align-items: flex-end;
    }
    
    .startHref{
        display: flex;
        width: 100%;
        height: 400px;
        align-items: flex-end;
        padding-bottom: 70px;
        justify-content: space-between;
    }
    .homeProducts{
        margin: 0 7% !important;
    }
    .homeInfo{
        margin: 10% 20%;
        display: flex;
        flex-direction: column;
        align-items: center; 
    }
    .homeRow2{
        text-align: center;
    }
    .homeMazoA{
        width: 70%;
    }
    .homeContactInfo{
        text-align: center;
    }
    .homeProductsBody{
        font-size: 21px;
    }
}

@media(max-width: medium){
    .startCertificates{
        display: flex;
        flex-direction: column;    
        align-items: center;
    }
    
    .startHref{
        display: flex;
        width: 100%;
        height: 400px;
        align-items: center;
        padding-bottom: 70px;
        justify-content: flex-start;
        flex-direction: column;
        margin-bottom: 80px;
    }

    .homeResp{
        display: flex;
        flex-direction: column !important;
    }
    
    .homeInfo{
        margin: 10% 5%;
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
    }

    .homeRow1{
        margin-top: 80px;
    }

    .homeRow2{
        text-align: left;
    }

    .disappear{
        height: 0 !important;
    }
    .homeMazoA{
        width: 100%;
    }
    .homeContactInfo{
        padding-top: 40px;
        text-align: left;
    }
    .homeProductsBody{
        font-size: 20px;
    }
}