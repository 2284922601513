@value first from '../../value/values.css';
.productTextBody{
    line-height: 33px;
    text-align: justify;
}

.green{
    color: first;
    padding: 10px;
}

.pelletButtonHref{
    border: 2px solid #ff8642;
    color: #ff8642;
    padding: 10px 25px;
    margin: 5px 0;
    font-weight: bold;
}
.pelletButtonHref:hover {
    background-color: #ff8642;
    color: white;
}