@value first from '../value/values.css';
@value second from '../value/values.css';
@value medium from '../value/values.css';

@media(min-width:medium){
    .logo{
    margin-right: 10px; 
    height: 70px;
    width: auto;
    }
    .title{
    font-size: 25px !important;
    }
    .topMenu{
        height: 5em;
        background-color: first !important;
    }
    .title{
        font-size: 20px;
        margin: 0;
    }
    .headerLanguagesButton{
        background: none;
        border: 0;
        padding: 3px;
        margin-right: 8px;
    }
    
    .headerLanguagesButton:hover{
        cursor: pointer;
    }
    
    .headerLanguagesButton:focus{
        background: none;
        border: none;
    }
    
    .headerFlags{
        transition: 0.1s ease-in-out;
        height: 15px;
        width: 27px;
        border-radius: 20%;
    }
    
    .headerFlags:hover{
        height: 18px;
        width: 31px;
    }
    
}

@media(max-width:medium){
    .logo{
    height: 60px;
    width: auto;
    margin-top: -10px;
    margin-right: 10px; 
    }
    .topMenu{
        background-color: first !important;
        border-radius: 0% !important;
    }
    .title{
        font-size: 17px;
        padding-bottom: 10px;
    }
    .headerLanguagesButton{
        background: none;
        border: 0;
        padding: 3px;
        margin-right: 8px;
        
    }
    
    .headerLanguagesButton:hover{
        cursor: pointer;
    }
    
    .headerLanguagesButton:focus{
        background: none;
        border: none;
    }
    
    .headerFlags{
        transition: 0.1s ease-in-out;
        height: 20px;
        width: 30px;
        border-radius: 20%;
    }
    
    .headerFlags:hover{
        height: 22px;
        width: 35px;
    }
    
}

.titleBody{
    font-size: 17px;
}

.menuHover:hover{
    background-color: transparent !important;
    font-weight: bold !important;
     /* font-size: 15px !important;  */
    transition: ease-in-out 0.2s all !important;
}
.menuHover{
    font-weight: 400 !important;
    font-size: 16px !important;
}

.headerMenu{
    z-index: 10000 !important;
}
.headerLanguages{
    z-index: 100000 !important;
    position: fixed;
    top:5px;
    right: 13%;
    background: none;
}
