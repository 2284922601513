@value first from '../value/values.css';
@value second from '../value/values.css';
@value third from '../value/values.css';
.productBox {
    margin: 0px;
}

.photo {
    width: 100%;
    height: 150px;
    border-radius: 2%;
}


.text {
    background-color: third;
    color: black;
    position: relative;
    height: 240px;
    width: 200px;
    border-radius: 2%;
    border-bottom: 2px first solid;
}
.textHead {
    margin: 3% 5% 0 5% !important;
    color: first;
    font-weight: 400;
    font-size: 23px;
    
}

.text:hover > .photo{
    opacity: 0;
    transition: ease-in-out 250ms all;
}

.text:hover{
    background-color: first;
    transition: ease-in-out 250ms all;
}

.text:hover > .textHead{ 
    margin-top: -43% !important;
    color: white;
    text-align: center;
    font-size: 35px;
    transition: ease-in-out 250ms all;
}
