.rodo{
    margin: 5%;
}

.rodoHead{
    margin-bottom: 5%;
}

.rodoBody{
    line-height: 35px !important;
}