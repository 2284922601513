@value first from '../value/values.css';
@value third from '../value/values.css';
@value medium from '../value/values.css';

@media(min-width:medium){
    .products{
        padding: 3% 0%;
        background-color: third;
        margin-bottom: -11px !important;
    }
    .productsDivider{
        margin: 15px 3% 20px !important;
    }
    .productColumn{
        padding: 5% 2% !important;
        height: 500px;
        
    }
    .productRow{
        padding: 0 4%!important;
        padding-bottom: 100px !important;
    }

    .materialColumn{
        margin-top: 50px !important;
        padding: 0px !important;
        position: relative !important;
    }

    .materialHead{
        position: absolute;
        color: white;
        z-index: 100;
        top:36%;
        left: 36%;
        font-size: 30px;
        width: 30%;
        padding: 10px 0;
        text-align: center;
        border: 1px solid white;
        border-bottom-left-radius: 30%;
        border-bottom-right-radius: 30%;
    }

    .materialImg{
        object-fit: cover;
        width: 100%;
        border-radius: 50%;
    }
    .green{
        color: first;
        text-align: center;
    }
    .catalogImg{
        width: 400px;
        margin: 0px 30px 20px 30px;
        border-radius: 1%;
        border: double first 1px ;
    }
}
@media(max-width:medium){
    .products{
        padding: 5% 5%;
        background-color: third;
        margin-bottom: -11px !important;
    }
    .productColumn{
        padding: 5% 2% !important;
        height: 480px;
    }
    .productRow{
        padding: 0px!important;
        padding-bottom: 50px !important;
    }
    .materialColumn{
        margin-top: 50px !important;
        padding: 0px !important;
        position: relative !important;
    }

    .materialHead{
        position: absolute;
        color: white;
        z-index: 100;
        top: 30%;
        left: 30%;
        font-size: 30px;
        width: 42%;
        padding: 10px 0;
        text-align: center;
        border: 1px solid white;
        border-bottom-left-radius: 30%;
        border-bottom-right-radius: 30%;
    }

    .materialImg{
        object-fit: cover;
        width: 100%;
        border-radius: 50%;
    }
    
    .green{
        color: first;
        text-align: center;
    }
    .catalogImg{
        width: 250px;
        margin: 0px 30px 20px 30px;
        border-radius: 2%;
        border: double first 2px ;
    }
    }

    .productColumn{
        display: flex !important;
        justify-content: center;
    }
