@value dark1 from '../value/values.css';
@value dark2 from '../value/values.css';
@value medium from '../value/values.css';
@value large from '../value/values.css';

@media(min-width:large){
    .footerLogo{
        display: flex;
        justify-content: center;
    }
}
@media(min-width:medium){
.footer{
    font-size: 30px;
    color: white;
    height: auto;
    background-color: dark1;
    padding-top: 50px;
    margin-top:13px;
    width: 100%;
}

.logo{
    margin-right: 10%;
    margin-left: 10%;
    width: 200px;
    height: auto;
    margin-top: -50px;
}

.footerLogo{
    display: flex !important;   
}
.footerColumn{
    display: flex !important;
    justify-content: space-between;
}

.footerCon{
    margin-left: 40px;
}

.footerNav{
    padding-right: 20px;
}

.footerNav a{
color: white;
}

.footerNav a:hover{
    font-weight: bold;
    color: white;
    transition: all ease-in-out 250ms;
}

.footerB{
    font-weight: bold;
    height: 35px;
    margin-bottom: 10px;
}
}
@media(max-width:medium){
    .footer{
        color: white;
        background-color: dark1;
    }
    
    .logo{
        width: 170px;
        height: auto;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    
    .footerLogo{
        display: flex !important;
        font-size: 20px;
        align-items: center;
        flex-direction: column;
    }
    .footerColumn{
        display: flex;
    }
    .footerNav{
        padding-top: 20px;
        font-size: 20px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    } 
    
    .footerNav a{
    color: white;
    }
    
    .footerNav a:hover{
        font-weight: bold;
        color: white;
        transition: all ease-in-out 250ms;
    }
    
    .footerB{
        font-weight: bold;
        height: 20px;
        margin-bottom: 10px;
        
    }

    .footerBody{
        font-size: 20px;
    }
    .copyright{
        margin-bottom: 10px !important;
    }
}

.copyright{
    display: flex !important;
    justify-content: center !important;
    background-color: dark2;
    height: 32px;
    padding: 0 !important;
}
.copyrightBody{
   font-weight: lighter;
   color: rgb(213, 213, 213);
   font-size: 12px;
   padding-top: 10px;
}