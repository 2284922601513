@value dark1 from '../../value/values.css';
@value mianText from '../../value/values.css';
@value mianFont from '../../value/values.css';
@value mianHoverBack from '../../value/values.css';
@value mianHoverText from '../../value/values.css';
@value mianPressedBack from '../../value/values.css';
@value mianPressedText from '../../value/values.css';
.mainButton {
    background-color: transparent;
    border: 0.5px solid white;
    font-size: mianText;
    font-family: mianFont;
    color: #FFFFFF;
    transition: 0.2s all ease-in-out;
    padding: 15px 30px;
}

.mainButton:hover {
    cursor: pointer;
    background-color:white;
    color: dark1;
}