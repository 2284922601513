@value first from '../value/values.css';
@value third from '../value/values.css';
@value second from '../value/values.css';
@value medium from '../value/values.css';

@media(min-width:medium){
.news{
    padding: 0 7%;
    background-repeat: no-repeat !important;
    background-position: top !important;
    background-attachment: fixed !important; 
    background-size: 100% 100% !important;
    margin-bottom: 14px;
}

.newsContain{
    background-color: white;
    padding: 3%;
    width: 90% !important;
}
.productRow{
    padding: 0 4%!important;
}

.newsDate{
    color: grey;
}

.green{
    color: first;
    text-align: center;
    padding: 4%;
}

.newsText{
    padding-top: 30px;
    line-height: 38px;
    display: flex;
    flex-direction: column;
}
}

@media(max-width:medium){
    .news{
        margin-bottom: 14px !important;
        background-repeat: no-repeat !important;
        background-position: top !important;
    }
    
    .newsContain{
        background-color: white;
        width: 90% !important;
        padding-bottom: 5%;
    }
    .productRow{
        padding: 1% !important;
    }
    
    .newsDate{
        color: grey;
    }
    
    .green{
        color: first;
        text-align: center;
        padding: 4%;
    }
    
    .newsText{
        padding-top: 30px;
        line-height: 35px;
    }
}

.newsMazoImg{
  align-self: center;
  width: 90%;
}