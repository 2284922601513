@value dark1 from '../../value/values.css';
@value first from '../../value/values.css';
@value medium from '../../value/values.css';
@media(min-width:medium){
.menu{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: sticky !important;
    top: 8em;
}

.menuHead{
    padding-right: 90px;
}

.menuBody{
    display: flex;
    flex-direction: column;
    text-align: left;
    line-height: 40px;
    padding-right: 20px;
    border-right: solid black thin;
}

.menuBody a{
    color: black;
}

.menuBody a:hover{
    color: first;
    font-weight: 700;
    transition: ease-in-out 300ms all;
}

.menuButton {
    border: 0.5px solid dark1;
    color: black;
    padding: 15px 55px;
    margin-right: 20px;
}

.menuButton:hover {
    background-color:dark1;
    color: white;
}
}
@media(max-width:medium){
    .menu{
        position: fixed !important;
        left: 100%;
    }
}