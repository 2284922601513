@value first from '../value/values.css';
@value second from '../value/values.css';
@value dark1 from '../value/values.css';
@value medium from '../value/values.css';

.ProductPageStart{
    display: flex;
    align-self: center;
    text-align: center;
    color: white;
    margin: 0 5%;
    
}
.green{
    color: second;
    text-align: center;
}
.productPageAll{
    text-align: center ;
}
.productPageButton{
    border: 0.5px solid first;
    color: first;
    padding: 15px 55px;
    margin: 10px 0;
}
.productPageButton:hover {
    background-color:dark1;
    color: white;
}


@media(min-width:medium){
.product{
    margin-top: 70px !important;
    margin-bottom: 50px !important;
}

.productText{
    padding: 0 10% 0 4% !important;
}

.seeMore{
    font-size: 23px;
}

.productImg{
    margin-top: 30px;
    border-radius: 4%;
    padding: 20px;
    height: 60rem; /* lub inna wybrana wysokość */
    object-fit: cover; /* Dopasowanie obrazu do ramki, bez zniekształcania */
    width: auto; /* Automatyczna szerokość */
}

.more{
    display: flex;
    justify-content: space-around;
}
.arrows {
    position: absolute;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
}

.arrows img {
    width: 50px;
    height: 50px;
}

.arrows:first-of-type {
    left: -5.5rem;
}

.arrows:last-of-type {
    right: -6.6rem;
}

.slick-slide.slick-center img {
    margin-left: auto;
    margin-right: auto;
}
}

@media(max-width:medium){
    .product{
        margin: 0 0 50px 0 !important;
    }
    
    .productText{
        padding: 0 10% 0 4% !important;
    }
    
    .productImg{
        margin-top: 20px;
        border-radius: 2%;
        
    }
    
    .more{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 800px;
    }
    .arrows {
        position: absolute;
        top: 1;
        opacity: 0;
    }
}

