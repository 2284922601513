@value heading1 from './values.css';
@value heading1Resp from './values.css';
@value heading2Resp from './values.css';
@value heading2 from './values.css';
@value heading3Resp from './values.css';
@value heading3 from './values.css';
@value headingFont from './values.css';
@value body1 from './values.css';
@value body1Resp from './values.css';
@value body1Font from './values.css';
@value body1FontW from './values.css';
@value body2 from './values.css';
@value body2Resp from './values.css';
@value body2Font from './values.css';
@value body2FontW from './values.css';
@value body3 from './values.css';
@value body3Resp from './values.css';
@value body3Font from './values.css';
@value medium from './values.css';
@value medium from './values.css';


@media(min-width: medium){

.head1{
    font-size: heading1;
    font-family: headingFont;
    font-weight: 400;
    color: white;
}

.head2 {
    font-size: heading2;
    font-family: headingFont;
    font-weight: 400;
}

.head3 {
    font-size: heading3;
    font-family: body1Font;
    font-weight: 500;
}

.bod1 {
    font-size: body1;
    font-family: body1Font;
    font-weight: body1FontW;
}

.bod2 {
    font-size: body2;
    font-family: body2Font;
    font-weight: body2FontW;
}

.bod3 {
    font-size: body3;
    font-family: body3Font;
}}

@media(max-width: medium){
    .head1{
        font-size: heading1Resp;
        font-family: headingFont;
        font-weight: 400;
        color: white;
    }
    
    .head2 {
        font-size: heading2Resp;
        font-family: headingFont;
        font-weight: 400;
    }
    
    .head3 {
        font-size: heading3Resp;
        font-family: body1Font;
        font-weight: 400;
    }
    
    .bod1 {
        font-size: body1Resp;
        font-family: body1Font;
        font-weight: body1FontW;
    }
    
    .bod2 {
        font-size: body2Resp;
        font-family: body2Font;
        font-weight: body2FontW;
    }
    
    .bod3 {
        font-size: body3Resp;
        font-family: body3Font;
    }}

