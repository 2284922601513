@value first from '../value/values.css';
@value third from '../value/values.css';
@value medium from '../value/values.css';

@media(min-width:medium){
    .gallery{
        padding: 5% 9%;
        background-color: third;
        margin-bottom: -11px !important;
    }
    .galleryRow{
        padding-top: 5% !important;
    }
    .galleryColumn{
        padding: 0.75% !important;
    }
    .galleryPhoto{
        height: 250px;
        object-fit: cover;
        width: 400px;
    }
    .green{
        color: first;
        text-align: center;
    }
}
@media(max-width:medium){
    .gallery{
        padding: 3% 3%;
        background-color: third;
    }
    .galleryColumn{
        display: flex !important;
        justify-content: center !important;
    }
    .galleryPhoto{
        height: 200px;
        width: 320px;
        margin: 15px 0px;
        object-fit: cover;
    }
    .green{
        color: first;
        text-align: center;
    }
}