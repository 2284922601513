@value medium from '../value/values.css';
@value second from '../value/values.css';
@value first from '../value/values.css';

.sale{
    border-top: 4px double white;
    border-bottom: 4px double white;
    background-color: first;
    color: white;
}

@media(min-width: medium){
    .sale{
        padding: 35px 20% !important;
    }
    .saleInfo{
        text-align: center;
    }
    .newsButton{
    margin-top: 10px;
    padding: 15px 80px !important;
}
}
@media(max-width: medium){
    .saleText{
        font-size: 17px;
    }
    .saleInfo{
        text-align: center;
    }
    .newsButton{
        padding: 10px  !important;
        margin-top: 5px;
        width: 70%;
    }
}