
@value medium from '../value/values.css';
@value big from '../value/values.css';
@value large from '../value/values.css';


@media(min-width: big) and (max-width:large){
    .offerText{
        color: white;
        padding: 1.5% 3%;
    }
    
    .offerTitle{
        text-align: center;
        background-color: rgb(255, 0, 0);
        border-radius: 2%;
        margin: 2% 10%;
    }
    
    .offerSub{
        margin: 0 10%;
        padding: 6% 10%;
    }

}
@media(min-width:large){
    .offerText{
        color: white;
        padding: 1.5% 3%;
    }
    
    .offerTitle{
        text-align: center;
        background-color: rgb(255, 0, 0);
        border-radius: 2%;
        margin: 2% 15%;
    }
    
    .offerSub{
        margin: 0 10%;
        padding: 6% 13%;
    }

}
@media(min-width: medium) and (max-width: big){
    .offerText{
        color: white;
        padding: 1.5% 3%;
    }
    
    .offerTitle{
        text-align: center;
        background-color: rgb(255, 0, 0);
        border-radius: 2%;
        margin: 2% 5%;
    }
    
    .offerSub{
        font-size: 20px;
        margin: 0 5%;
        padding: 3% 5%;
    }

}
@media(max-width: medium){
    .offerText{
        color: white;
        padding: 10% 3%;
    }
    
    .offerTitle{
        text-align: center;
        background-color: rgb(255, 0, 0);
        border-radius: 2%;
        margin: 2% 20%;
    }
    
    .offerSub{
        font-size: 20px;
        margin: 0 5%;
        padding: 3% 5%;
    }

}
.offer{
    background-color: #161616;
    padding: 1.5% 3% !important;
}

.offerImg{
    border-radius: 3%;
    opacity: 0.6; 
   
}

